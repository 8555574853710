const {products} = require('./products.init');


function initEvents() {
    const listCollections = ['toysCollection', 'dressCollection', 'printsCollection'];
    const listCollectionsEls = listCollections.map(id => document.getElementById(id));
    const filterEls = document.getElementsByName('typeProductFilter');


    filterEls.forEach(el => el.addEventListener('change', (event) => {
        const inpEl = event.target;

        if (inpEl.checked) {

            listCollectionsEls.forEach(collection => {
                if (collection.id === inpEl.value || inpEl.value === 'all') {
                    collection.classList.remove('products__section--hidden');
                } else {
                    collection.classList.add('products__section--hidden');
                }
            });

        }
    }));


}

function showProducts(containerId, products) {
    const cardTemplate = document.getElementById('templateCard');
    const cardsContainer = document.querySelector('#' + containerId + ' div ');
    // const titleSection = cardsContainer.getElementById('titleToysCollection');

    // titleSection.innerText = productObj.title;
    cardsContainer.innerHTML = '';

    for (let product of products) {
        if (!product.hidden) {
            const newCard = cardTemplate.content.cloneNode(true);
            const headerCard = newCard.getElementById('titleCard');
            const linkImgCard = newCard.getElementById('linkImgCard');
            const collectionCard = newCard.getElementById('collectionCard');
            const imgCard = newCard.getElementById('imgCard');

            const titleImgObj = product.images[0]
            const titleFileImg = titleImgObj.files[0]

            headerCard.innerText = product.name;
            collectionCard.innerText = product.collection;
            imgCard.src = titleFileImg
            linkImgCard.href = product.link + `?groupId=${product.groupId}&id=${product.id}`;
            // linkImgCard['data-id'] = product.id;

            /**
             * linkImgCard
             */

            // linkImgCard.addEventListener('click', (event) => {
            //     const productsId = linkImgCard['data-id']
            //     localStorage.setItem('product-id', JSON.stringify(productsId));
            // })

            cardsContainer.appendChild(newCard);
        }
    }
}

// const markFilter = document.getElementById('markFilter')
// const resetBtn = document.getElementById('resetBtn')
//
// resetBtn.addEventListener('click', function (event) {
//     listProducts.forEach(product => product.hidden = false)
//     showProducts()
// })
//
// markFilter.addEventListener('change', function (event) {
//     const value = event.target.value
//     for (let product of listProducts) {
//         product.hidden = value !== product.mark.toLowerCase()
//     }
//
//     console.log(listProducts)
//     showProducts()
//
// })
/**
 * run
 */
initEvents();

showProducts('toysCollection', products.toys);
// showProducts('dressCollection', products.dress);
// showProducts('printsCollection', products.prints);



































